import styled from "styled-components";

export const VerticalEmbedsWrapper = styled.section`
	position: relative;
	width: 100%;
    max-width: 100%;
	padding: 8rem 0 6rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colours.tan};
    .vertical-embeds-wrapper{
        position: relative;
        width: 100%;
        .background-bean{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
			object-fit: contain;
			width: 100%;
			height: 100%;
			z-index: 1;
        }
        .vertical-embeds-content{
            position: relative;
            z-index: 5;
            width: 100%;
        }
    }
    @media (max-width: 900px) {
		padding: 2rem 0 4rem 0;
    }
`;

export const VerticalEmbedsBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const VerticalEmbedsTitle = styled.h2`
    font-weight: bold;
	font-size: 68px;
	text-transform: uppercase;
	color: #1471D2;
	line-height: 1;
	margin-bottom: 0;
    span{
		font-size: 70%;
		border-bottom: 4px solid;
		position: relative;
		top: -13px;
		letter-spacing: -1px;
    }
    @media (max-width: 900px) {
        font-size: 45px;
    }
`;

export const VerticalEmbedsHeading = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #555;
`;

export const VerticalEmbedsCopy = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: #777;
`;

export const VerticalEmbedsVideoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4rem;
	margin-bottom: 2rem;
	justify-content: center;
	@media (max-width: 1400px) {
		gap: 10px;
	}
`;

export const VerticalEmbedsVideo = styled.div`
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow: hidden;
  position: relative;
	  &.videoOne{
		top: -50px;
	  }
	  &.videoThree{
		top: -50px;
	  }
      &.videoTwo{
          top: 0;
      }
      .title-row{
          display: block;
          width: 100%;
      }
      .video-holder{
          position: relative;
		  padding-bottom: 177.78%;
          height: 0;
          overflow: hidden;
      }
      .video-div{
		  position: absolute;
		  top: 0;
		  left: 0;
		  right: 0;
		  bottom: 0;
          width: 100%;
          height: 100%;
		  display: flex;
		  justify-content: center;
		  align-items: center;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
          .play-video-button{
              svg{
                  transition: all 0.4s ease;
                  fill: ${({ theme }) => theme.colours.red};
              }
          }
          &:hover{
			  .play-video-button{
				  svg{
					  fill: ${({ theme }) => theme.colours.yellow};
				  }
			  }
          }
          iframe{
			  position: absolute;
			  top: 0;
			  left: 0;
			  right: 0;
			  bottom: 0;
			  width: 100%;
			  height: 100%;
          }
      }
`;

export const VerticalEmbedsButton = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;