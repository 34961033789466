import React from 'react';
import PropTypes from 'prop-types';
import {Image, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import Ribbon from 'components/shared/Ribbon';
import { CardComponent, HoverWrapper, MobileButtonWrapper } from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';

import translate from 'helpers/translate';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';
import styled from "styled-components";

export const FeatCardComponent = styled.article`
  position: relative;
  max-width: 560px;
  overflow: hidden;
  width: 100%;
  justify-self: center;

  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.blue};
    max-height: 200px;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 80%;
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 320px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 460px;
    }

    > a {
      display: flex;
      flex-direction: column;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: 0.4s;

      ${({ type }) => (type === 'product' ? `max-width: 35%;` : '')}

      &:hover {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
  }

  header {
    .button-love {
      position: absolute;
      top: 0;
      right: 0;
      margin: ${({ theme }) => theme.spacing(16)};
      z-index: 10;
    }
  }

  footer {
    height: 100%;
    padding: ${({ theme }) => theme.spacing({ top: 32, left: 32, right: 32, bottom: 48 })};

    h2 {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(48)};
      }
    }
    h3 {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(48)};
      }
    }

    span {
      &.emphasis.small {
        display: block;
        color: ${({ theme }) => theme.colours.tan};
        text-align: center;
        margin-bottom: ${({ theme }) => theme.spacing(32)};

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          margin-bottom: ${({ theme }) => theme.spacing(48)};
        }
      }
    }

    button {
      margin: 0 auto;
    }
  }
`;


const FeaturedCard = ({
                      sitecoreContext = {},
                      image = {},
                      promoImage = {},
                      title = '',
                      link = '',
                      itemId = '',
                      type = 'featured',
                      classValue = '',
                    }) => {
const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;
const device = useDeviceResize();
console.log(image);


  return (
    <CardComponent type={type} className={`${classValue} featured-card-component`}>
      <header>
        {!isServer() && <Button type="love" size={device === 'mobile' ? 'small' : 'large'} itemId={itemId} />}
      </header>

      {!isServer() && (
        <ConditionalComponentWrapper
          condition={link}
          wrapper={children => (
            <Link href={link.href} className="button-link">
              {children}
            </Link>
          )}
        >
          <div className="image-container">
            {image?.value.src &&
              <Image
                field={image}
                imageParams={{mw: 800, mh: 800 }}
              />
            }
            {device === 'desktop' && (
              <HoverWrapper>
                {promoImage?.url && (
                  <img src={promoImage?.url} alt={promoImage?.alt} className="hover-image" />
                )}
                <Ribbon variant="yellow" />
              </HoverWrapper>
            )}
          </div>

          {(device === 'mobile' || device === 'tablet') && (
            <MobileButtonWrapper type={type}>
              <span>{translate(viewBag, 'BushsBeans.Search.Grids.Recipes.MobileLabel', 'view recipe')}</span>
            </MobileButtonWrapper>
          )}

          <footer>
            {title && (
              <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(typeof title === 'object' ? title.value : title, trademarkReplacements) }} />
            )}
          </footer>
        </ConditionalComponentWrapper>
      )}
    </CardComponent>
  );
};

export default withSitecoreContext()(FeaturedCard);

FeaturedCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};