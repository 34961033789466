import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CirclePlayBtnSvg from "./SubTemplates/CirclePlayBtnSvg.jsx";
import YoutubePlayer from './SubTemplates/YoutubePlayer.jsx';
import BigBeanVector from '../../assets/images/BigBeanVector.svg';
import Button from "../shared/Button/Button.jsx";
import {
    VerticalEmbedsWrapper,
    VerticalEmbedsTitle,
    VerticalEmbedsHeading,
    VerticalEmbedsCopy,
    VerticalEmbedsBackground,
    VerticalEmbedsVideoContainer,
    VerticalEmbedsVideo,
    VerticalEmbedsButton,
} from './VerticalEmbeds.styles.js';



const VerticalEmbeds = ({ fields, sitecoreContext }) => {
    const {
        title,
        heading,
        copy,
        backgroundImg,
        videoOneId,
        videoOnePoster,
        videoTwoId,
        videoTwoPoster,
        videoThreeId,
        videoThreePoster,
        button,
    } = fields;
    const [isVideoClicked, setIsVideoClicked] = useState(false);
    const [activeVideoId, setActiveVideoId] = useState(null);
    const handleVideoClick = (videoId) => {
        console.log('Clicked video ID:', videoId);
        setActiveVideoId(videoId);
    };

    const handleVideoEnd = () => {
        setActiveVideoId(null);
    };
    const getPoster = (videoId, customPoster) =>
        customPoster?.value?.src || `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    return (
        <VerticalEmbedsWrapper>
            <div className="title-row">
                {title && (
                    <VerticalEmbedsTitle
                        dangerouslySetInnerHTML={{ __html: title.value }}
                    />
                )}
            </div>
            <div className="vertical-embeds-wrapper">
                {backgroundImg && backgroundImg.value ? (
                    <VerticalEmbedsBackground className="background-bean" src={backgroundImg.value.src} alt={backgroundImg.value.alt} />
                ) : (
                    <img className="background-bean" src={BigBeanVector} width="1728" height="913" alt="big bean background decoration graphic" />
                )}
                <div className="vertical-embeds-content">
                    {heading && (
                        <VerticalEmbedsHeading
                            dangerouslySetInnerHTML={{ __html: heading.value }}
                        />
                    )}
                    {copy && (
                        <VerticalEmbedsCopy
                            dangerouslySetInnerHTML={{ __html: copy.value }}
                        />
                    )}
                    <VerticalEmbedsVideoContainer>
                        {videoOneId?.value && (
                            <VerticalEmbedsVideo className="videoOne">
                                <div className="video-holder">
                                    <div
                                        className="video-div"
                                        style={{ backgroundImage: `url(${getPoster(videoOneId.value, videoOnePoster)})` }}
                                        onClick={() => handleVideoClick(videoOneId.value)}
                                    >
                                        {activeVideoId !== videoOneId.value ? (
                                            <div className="inner-content">
                                                <button className="play-video-button" aria-label="Play video">
                                                    <CirclePlayBtnSvg />
                                                </button>
                                            </div>
                                        ) : (
                                            <YoutubePlayer
                                                isClicked={true}
                                                videoId={videoOneId.value}
                                                onVideoEnd={handleVideoEnd}
                                            />
                                        )}
                                    </div>
                                </div>
                            </VerticalEmbedsVideo>
                        )}

                        {videoTwoId?.value && (
                            <VerticalEmbedsVideo className="videoTwo">
                                <div className="video-holder">
                                    <div
                                        className="video-div"
                                        style={{ backgroundImage: `url(${getPoster(videoTwoId.value, videoTwoPoster)})` }}
                                        onClick={() => handleVideoClick(videoTwoId.value)}
                                    >
                                        {activeVideoId !== videoTwoId.value ? (
                                            <div className="inner-content">
                                                <button className="play-video-button" aria-label="Play video">
                                                    <CirclePlayBtnSvg />
                                                </button>
                                            </div>
                                        ) : (
                                            <YoutubePlayer
                                                isClicked={true}
                                                videoId={videoTwoId.value}
                                                onVideoEnd={handleVideoEnd}
                                            />
                                        )}
                                    </div>
                                </div>
                            </VerticalEmbedsVideo>
                        )}

                        {videoThreeId?.value && (
                            <VerticalEmbedsVideo className="videoThree">
                                <div className="video-holder">
                                    <div
                                        className="video-div"
                                        style={{ backgroundImage: `url(${getPoster(videoThreeId.value, videoThreePoster)})` }}
                                        onClick={() => handleVideoClick(videoThreeId.value)}
                                    >
                                        {activeVideoId !== videoThreeId.value ? (
                                            <div className="inner-content">
                                                <button className="play-video-button" aria-label="Play video">
                                                    <CirclePlayBtnSvg />
                                                </button>
                                            </div>
                                        ) : (
                                            <YoutubePlayer
                                                isClicked={true}
                                                videoId={videoThreeId.value}
                                                onVideoEnd={handleVideoEnd}
                                            />
                                        )}
                                    </div>
                                </div>
                            </VerticalEmbedsVideo>
                        )}
                    </VerticalEmbedsVideoContainer>
                </div>
            </div>
            {button?.value?.text && button?.value?.href && (
                <div className={`content-container btn-wrapper justify-center`}>
                    <Button
                        text={button.value.text}
                        href={button.value.href}
                        target={button.value.target}
                        {...button.value}
                        variant="brown"
                        className="fade-in-up"
                    />
                </div>
            )}
        </VerticalEmbedsWrapper>
    );
};

VerticalEmbeds.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.object,
        heading: PropTypes.object,
        copy: PropTypes.object,
        backgroundImg: PropTypes.object,
        videoOneId: PropTypes.object,
        videoOnePoster: PropTypes.object,
        videoTwoId: PropTypes.object,
        videoTwoPoster: PropTypes.object,
        videoThreeId: PropTypes.object,
        videoThreePoster: PropTypes.object,
        button: PropTypes.object,
    }),
    sitecoreContext: PropTypes.object.isRequired,
};

export default withSitecoreContext()(VerticalEmbeds);