import styled from 'styled-components';

const RandomDefaultStyle = styled.div``;
export default RandomDefaultStyle;

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      heading: theme.colours.yellow,
      copy: theme.colours.tan,
    },
    yellow: {
      background: theme.colours.yellow,
      copy: theme.colours.brown,
      heading: theme.colours.red,
    },
    blue: {
      background: theme.colours.blue,
      heading: theme.colours.yellow,
      copy: theme.colours.tan,
    },
    tan: {
      background: theme.colours.tan,
      copy: theme.colours.red,
      heading: theme.colours.red,
    },
    brown: {
      background: theme.colours.brown,
      text: theme.colours.yellow,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const FeaturedPagesPromoComponent = styled.section`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(80)};
  padding-bottom: ${({ theme }) => theme.spacing(80)};
  background: ${({ theme, background }) => theme.colours[background]};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: ${({ theme }) => theme.spacing(60)};
    padding-bottom: ${({ theme }) => theme.spacing(50)};
  }

  &.featured-blog-recipes{
      &.title-yellow{
          .related-grid article footer h3{
              color: ${({ theme }) => theme.colours.yellow} !important;
          }
      }
      &.title-red{
          .related-grid article footer h3{
              color: ${({ theme }) => theme.colours.red} !important;
          }
      }
      &.title-tan{
          .related-grid article footer h3{
              color: ${({ theme }) => theme.colours.tan} !important;
          }
      }
  }

  & h1 {
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(48)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }
  .bean-top-shine{
    margin-top: 30px;
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.blue};
    margin-bottom: ${({ theme }) => theme.spacing(15)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .section-heading {
    margin-bottom: ${({ theme }) => theme.spacing(24)};
    color: ${({ theme }) => theme.colours.red};
    font-size: clamp(2rem, 4vw, 4.5rem);
    font-weight: 600;
    font-family: "apercu condensed pro", impact;
    line-height: clamp(2.125rem, 4vw, 4.75rem);
    text-align: center;
    text-transform: uppercase;
    &.brown{
        color: ${({ theme }) => theme.colours.brown} !important;
    }
    &.color-red{
        color: ${({ theme }) => theme.colours.red} !important;
    }
    &.color-tan{
        color: ${({ theme }) => theme.colours.tan} !important;
    }
    &.color-brown {
        color: ${({theme}) => theme.colours.brown} !important;
    }
    &.color-blue {
        color: ${({theme}) => theme.colours.blue} !important;
    }
    &.color-white{
        color: ${({theme}) => theme.colours.white} !important;
    }
    &.color-yellow{
        color: ${({theme}) => theme.colours.yellow} !important;
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(28)};
    }
  }
    &.landingsurvey{
        .section-heading{
            color: #C75127;
        }
    }

  & .bean-burst {
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(15)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .section-copy{
      margin-bottom: ${({ theme }) => theme.spacing(20)};
      line-height: 1.65;
      &.tan{
          color: ${({ theme }) => theme.colours.tan} !important;
      }
      &.brown{
          color: ${({ theme }) => theme.colours.brown} !important;
      }
  }  
  
  .related-grid{
    max-width: 90%;
  }
    
  .container{
    width: 1580px;
    max-width: 100%;
    margin: auto;
    position: relative;
  }
  .copy-container{
      width: 960px;
      max-width: 100%;
      margin: auto;
      position: relative;
      margin-bottom: ${({ theme }) => theme.spacing(28)};
  }
  .button-container{
    display: flex;
    justify-content: center;
  }
  .slick-slider{
    padding-bottom: 45px;
    @media (max-width: ${({ theme }) => theme.spacing(650)}) {
      width: 80%;
      margin: auto;
      padding-bottom: 30px;
    }
    .slick-list{
      overflow: visible;
      .slick-track{
        display: flex;
        .slick-slide{
          article{
            padding: 0 15px;
          }
        }
      }
    }
  }
  .section-heading{
    color: ${({ theme, background }) =>
        getVariantColours({ variant: background, theme }).heading} !important;
  }
  .copy-container{
    color: ${({ theme, background }) =>
        getVariantColours({ variant: background, theme }).copy};
  }
  .section-copy{
    color: ${({ theme, background }) =>
        getVariantColours({ variant: background, theme }).copy} !important;
  }
  &.featured-blog-recipes.title-red .related-grid article footer h3 {
    color: ${({ theme, background }) =>
        getVariantColours({ variant: background, theme }).heading} !important;
  }
  .featured-grid{
    justify-content: center;
  }
`;

export const RelatedPagesPromoComponent = styled.section`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(80)};
  padding-bottom: ${({ theme }) => theme.spacing(80)};
  background: ${({ theme, background }) => theme.colours[background]};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: ${({ theme }) => theme.spacing(60)};
    padding-bottom: ${({ theme }) => theme.spacing(50)};
  }

  &.featured-blog-recipes{
      &.title-yellow{
          .related-grid article footer h3{
              color: ${({ theme }) => theme.colours.yellow} !important;
          }
      }
      &.title-red{
          .related-grid article footer h3{
              color: ${({ theme }) => theme.colours.red} !important;
          }
      }
      &.title-tan{
          .related-grid article footer h3{
              color: ${({ theme }) => theme.colours.tan} !important;
          }
      }
  }

  & h1 {
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(48)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }
  .bean-top-shine{
    margin-top: 30px;
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.blue};
    margin-bottom: ${({ theme }) => theme.spacing(15)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .section-heading {
    margin-bottom: ${({ theme }) => theme.spacing(24)};
    color: ${({ theme }) => theme.colours.red};
    font-size: clamp(2rem, 4vw, 4.5rem);
    font-weight: 600;
    font-family: "apercu condensed pro", impact;
    line-height: clamp(2.125rem, 4vw, 4.75rem);
    text-align: center;
    text-transform: uppercase;
    &.brown{
        color: ${({ theme }) => theme.colours.brown} !important;
    }
    &.color-red{
        color: ${({ theme }) => theme.colours.red} !important;
    }
    &.color-tan{
        color: ${({ theme }) => theme.colours.tan} !important;
    }
    &.color-brown {
        color: ${({theme}) => theme.colours.brown} !important;
    }
    &.color-blue {
        color: ${({theme}) => theme.colours.blue} !important;
    }
    &.color-white{
        color: ${({theme}) => theme.colours.white} !important;
    }
    &.color-yellow{
        color: ${({theme}) => theme.colours.yellow} !important;
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(28)};
    }
  }
    &.landingsurvey{
        .section-heading{
            color: #C75127;
        }
    }

  & .bean-burst {
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(15)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .section-copy{
      margin-bottom: ${({ theme }) => theme.spacing(20)};
      line-height: 1.65;
      &.tan{
          color: ${({ theme }) => theme.colours.tan} !important;
      }
      &.brown{
          color: ${({ theme }) => theme.colours.brown} !important;
      }
  }  
  
  .related-grid{
    max-width: 90%;
  }
    
  .container{
    width: 1580px;
    max-width: 100%;
    margin: auto;
    position: relative;
  }
  .copy-container{
      width: 960px;
      max-width: 100%;
      margin: auto;
      position: relative;
      margin-bottom: ${({ theme }) => theme.spacing(28)};
  }
  .button-container{
    display: flex;
    justify-content: center;
  }
  .slick-slider{
    padding-bottom: 45px;
    @media (max-width: ${({ theme }) => theme.spacing(650)}) {
      width: 80%;
      margin: auto;
      padding-bottom: 30px;
    }
    .slick-list{
      overflow: visible;
      .slick-track{
        display: flex;
        .slick-slide{
          article{
            padding: 0 15px;
          }
        }
      }
    }
  }
`;

export const RelatedPagesPromoComponentFS = styled.section`
  text-align: center;
  background: ${({ theme }) => theme.colours.tan};
  padding-top: 0;
  padding-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing(80)};
  margin-bottom: ${({ theme }) => theme.spacing(80)};
  overflow: hidden;
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: ${({ theme }) => theme.spacing(160)};
    margin-bottom: ${({ theme }) => theme.spacing(160)};
  }
  &.landingsurvey{
      margin-top: 5rem !important;
  }
  #landingSurvey{
      margin-left: auto;
      margin-right: auto;
      width: 1440px;
      max-width: 90%;
      margin-top: 3rem;
      .bushs-beans-foodservices-card.recipe h3{
          min-height: auto;
          font-size: 22px;
          text-align: center;
          text-transform: capitalize;
      }
  }
  .bushs-beans-foodservices-card {
    &.recipe{
      header{
        display: none;
      }
      footer{
        background: ${({ theme }) => theme.colours.blue};
        text-align: left;
        padding: ${({ theme }) => theme.spacing({ top: 32, left: 32, right: 32, bottom: 30 })};
        h3{
          margin-bottom: 0;
          text-align: left;
          min-height: 0;
          ${({ theme }) => theme.breakPoints.minBp('tablet')}{
            min-height: 72px;
          }
        }
        .button-link{
          display: none;
        }
      }
    }
  }
  & h1 {
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(48)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }
  .section-heading {
    margin-bottom: ${({ theme }) => theme.spacing(48)};
    color: ${({ theme }) => theme.colours.red};
    font-size: clamp(2rem, 4vw, 3.5rem);
    font-weight: 600;
    font-family: "apercu condensed pro", impact;
    line-height: clamp(2.125rem, 4vw, 4.75rem);
    text-align: center;
    text-transform: uppercase;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    &.brown{
      color: ${({ theme }) => theme.colours.brown};
    }
      &.sv-color{
          color: #C75127;
          font-size: clamp(2rem, 4vw, 2.5rem);
          margin-bottom: 3rem;
      }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }
  & .bean-burst {
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.brown};
    margin-bottom: ${({ theme }) => theme.spacing(15)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .container{
    width: 1580px;
    max-width: 100%;
    margin: auto;
    position: relative;
  }
  .button-container{
    display: flex;
    justify-content: center;
  }
  .slick-slider{
    padding-bottom: 45px;
    @media (max-width: ${({ theme }) => theme.spacing(650)}) {
      width: 80%;
      margin: auto;
      padding-bottom: 30px;
    }
      .slick-list{
          overflow: visible;
          .slick-track{
              display: flex;
              .slick-slide{
                  div{
                      height: 100%;
                      box-sizing: border-box;
                  }
                  .image-container{
                      max-height: 330px;
                  }
                  article{
                      height: inherit;
                      padding: 0 15px;
                      max-width: 90%;
                      width: 90%;
                      box-sizing: border-box;
                  }
              }
              @media (max-width: ${({ theme }) => theme.spacing(800)}) {
                  .slick-slide{

                      div{
                          height: 100%;
                      }
                      article{
                          padding: 0 15px;
                          height: inherit;
                          .image-container{
                              height: 294px;
                          }
                      }
                  }
              }
              @media (max-width: ${({ theme }) => theme.spacing(1400)}) {
                  .slick-slide{
                      article{
                          .image-container{
                              max-height: 330px;
                          }
                      }
                  }
              }
          }
      }
    }
  }
`;

export const RelatedPagesPromoComponentBluey = styled.section`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(80)};
  padding-bottom: ${({ theme }) => theme.spacing(150)};
  background: ${({ theme, background }) => theme.colours[background]};
  overflow: hidden;
  .bean-burst{
    color: ${({ theme }) => theme.colours.blue};
  }
  h2{
    font-family: 'hello headline', sans-serif;
    color: ${({ theme }) => theme.colours.blue};
  }
  article footer .button-link{
    display: none;
  }
  article footer .category-tag{
    display: none;
  }
  article footer h3{
    display: block;
    text-align: left;
    margin-bottom: 0;
    line-height: 1.2;
  }

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: ${({ theme }) => theme.spacing(60)};
    padding-bottom: ${({ theme }) => theme.spacing(50)};
  }
  .bean-top-shine{
    margin-top: 30px;
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.blue};
    margin-bottom: ${({ theme }) => theme.spacing(15)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .section-heading {
    font-size: clamp(2rem, 4vw, 4.5rem);
    font-weight: 600;
    line-height: clamp(2.125rem, 4vw, 4.75rem);
    text-align: center;
    text-transform: uppercase;
    font-family: 'hello headline', sans-serif;
    color: ${({ theme }) => theme.colours.blue};
    margin: 0 auto 30px auto;
    max-width: 80%;
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      font-size: 40px;
      line-height: 1.15;
    }
  }
  .container{
    width: 1580px;
    max-width: 90%;
    margin: auto;
    position: relative;
  }
  .button-container{
    display: none;
    justify-content: center;
    margin-bottom: 40px;
    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      display: flex;
    }
  }
  .slick-slider{
    padding-bottom: 45px;
    @media (max-width: ${({ theme }) => theme.spacing(650)}) {
      width: 80%;
      margin: auto;
      padding-bottom: 30px;
    }
    .slick-list{
      overflow: visible;
      .slick-track{
        display: flex;
        .slick-slide{
          height: inherit;
          article{
            padding: 0 8px 0 8px;
            box-sizing: border-box;
            height: 100%;
            .image-container{
              max-height: 320px;
            }
            footer{
              padding: 0 25px;
              box-sizing: border-box;
              min-height: 122px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    padding-bottom: 80px;
  }
  @media (max-width: 799px) {
    padding-bottom: 20px;
    padding-top: 40px;
    .container{
      max-width: 100%;
    }
    .slick-slider .slick-list .slick-track .slick-slide article footer h3{
      font-size: 24px;
    }
  }
  @media (max-width: 650px) {
    .slick-slider .slick-list .slick-track .slick-slide article footer{
      min-height: 100px;
    }
  }
`;

export const FeaturedGrid = styled.div`
  max-width: 90%;
  grid-auto-rows: auto;
  gap: clamp(3.75rem, 6vh, 6.25rem) clamp(1.875rem, 1.75vw, 3.125rem);
  display: grid;
  grid-auto-flow: dense;
  margin: 0 auto;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  article{
    width: 440px;
    max-width: 100%;
  }
`;