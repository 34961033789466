import React, { Component } from 'react';

class YoutubePlayer extends Component  {
    constructor(props) {
        super(props);
        this.player = null;
        this.playerRef = React.createRef();
    }

    componentDidMount() {
        //console.log('YoutubePlayer: ComponentDidMount');
        // Adding event listener for YouTube Iframe API ready event
        document.addEventListener('youtubeIframeAPIReady', this.initializePlayer);
        // Check if the YouTube Iframe API is ready
        if (window.YT) {
            this.initializePlayer();
        } else {
            //console.error('YoutubePlayer: YT object not available yet, waiting for youtubeIframeAPIReady event...');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.videoId !== prevProps.videoId) {
            this.initializePlayer();
        }

        if (this.props.isClicked && !prevProps.isClicked) {
            if (this.player && this.player.playVideo) {
                this.player.playVideo();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('youtubeIframeAPIReady', this.initializePlayer);
        if (this.player) {
            //console.log('YoutubePlayer: Destroying player...');
            this.player.destroy();
        }
    }

    initializePlayer = () => {
        //console.log('YoutubePlayer: Initializing player with videoId:', this.props.videoId);
        if (window.YT && window.YT.Player && this.playerRef.current) {
            this.player = new window.YT.Player(this.playerRef.current, {
                videoId: this.props.videoId,
                events: {
                    onReady: (event) => {
                        console.log('YoutubePlayer: Player is ready');
                        this.onPlayerReady(event);
                        // Play video immediately if isClicked is already true during initialization
                        if (this.props.isClicked) {
                            this.player.playVideo();
                        }
                    },
                    onStateChange: (event) => {
                        //console.log(`YoutubePlayer: Player state changed to ${event.data}`);
                        this.onPlayerStateChange(event);
                    }
                }
            });
        } else {
            //console.error('YoutubePlayer: Failed to initialize player, YT or YT.Player not available');
        }
    }

    onPlayerReady = (event) => {
        this.player = event.target;
    };

    onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.ENDED) {
            //console.log('YoutubePlayer: Video has ended');
            if (this.props.onVideoEnd) {
                this.props.onVideoEnd();
            }
        }
    }

    render() {
        //console.log('YoutubePlayer: Render');
        return <div ref={this.playerRef} aria-label="YouTube video player" title={this.props.title}></div>;
    }

    togglePlayback = () => {
        if (this.player) {
            if (this.player.getPlayerState() === window.YT.PlayerState.PLAYING) {
                this.player.pauseVideo();
            } else {
                this.player.playVideo();
            }
        }
    }
}

export default YoutubePlayer;