/* eslint-disable react/prop-types */
import React, {useRef, useState, useEffect} from 'react';
// import { NavLink } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import {Placeholder, VisitorIdentification, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import GlobalStyle from 'style/global/GlobalStyles';
import MainNav from 'components/MainNav';
import Footer from 'components/Footer';
import AlertRibbon from 'components/AlertRibbon';
import SearchBox from 'components/SearchBox';
import UtilityNav from "components/UtilityNav/UtilityNav.jsx";
import styled from 'styled-components';
import {useOnClickOutside} from 'hooks';


/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

/* Leaving the navigation boilerplate as an example of a translation implementation */
// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not simply to support disconnected mode.
// let Navigation = ({ t, i18n }) => (
//   <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom">
//     <h5 className="my-0 mr-md-auto font-weight-normal">
//       <NavLink to="/" className="text-dark">
//         <img src={logo} alt="Sitecore" />
//       </NavLink>
//     </h5>
//     <nav className="my-2 my-md-0 mr-md-3">
//       <a className="p-2 text-dark" href="https://jss.sitecore.net" target="_blank" rel="noopener noreferrer">
//         {t('Documentation')}
//       </a>
//       <NavLink to="/styleguide" className="p-2 text-dark">
//         {t('Styleguide')}
//       </NavLink>
//       <NavLink to="/graphql" className="p-2 text-dark">
//         {t('GraphQL')}
//       </NavLink>
//     </nav>
//   </div>
// );

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const SearchBoxInner = styled.div`
    position: absolute;
    right: 0;
    width: 100%;
    height: 180px;
    top: ${({search}) => (search ? '0' : '-180px')};
    background: ${({theme}) => theme.colours.tan};
    transition: top 0.3s;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    & .search-box {
        max-width: 90%;
        width: 100%;
        margin: 0 auto;

        ${({theme}) => theme.breakPoints.minBp('desktop')} {
            max-width: 60%;
        }
    }
`;

export const SearchBoxContainer = styled.div`
    position: relative;
    width: 100%;
    z-index: ${({theme}) => theme.zindex.search.main};
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({theme}) => theme.zindex.search.overlay};
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: ${({search}) => (search ? 'visible' : 'hidden')};
    cursor: pointer;
    opacity: ${({search}) => (search ? '1' : '0')};
    backdrop-filter: blur(1px);
    transition: opacity 0.2s;
`;

const Layout = ({route, sitecoreContext}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState(false);
    const [noSearchValue, setNoSearchValue] = useState(false);
    const searchRef = useRef(null);
    const { site } = sitecoreContext;
    useOnClickOutside(
        searchRef,
        () => {
            setSearch(false);
        },
        'search-button'
    );

    const {globalContent = {}, recipeInfo = {}, language = 'en'} = sitecoreContext;
    const {metadata = {}, siteName = ''} = globalContent;
    const {
        pageTitle = '',
        metadataTitle = '',
        metadataDescription = '',
        metadataImage = '',
        metadataUrl = '',
        canonicalUrl = '',
        siteDomain = '',
        hreflangLinks = [],
    } = metadata;

    const hideBranding = metadataTitle === 'hideBranding' || metadataDescription === 'hideBranding';

    useEffect(() => {
        if (metadataDescription === 'devMode') {
            const urlParams = new URLSearchParams(window.location.search);
            const preview = urlParams.get('preview') === 'true';
            if (!preview) {
                window.location.href = 'https://www.bushbeans.com/en_US/not-found';
            }
        }
    }, [metadataDescription]);

    return (
        <React.Fragment>
            {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
            <Helmet>
                <title>{`${pageTitle || route?.fields?.pageTitle?.value} | ${siteName || 'BUSH’S® Beans'}`}</title>

                {metadataTitle && <meta name="title" content={metadataTitle}/>}
                {metadataDescription && <meta name="description" content={metadataDescription}/>}
                <link rel="canonical" href={canonicalUrl || `${siteDomain}${metadataUrl}`}/>
                {language && <meta httpEquiv="content-language" content={language}></meta>}
                {Array.isArray(hreflangLinks) &&
                    hreflangLinks.length > 0 &&
                    hreflangLinks.map((lang, index) => (
                        <link
                            key={`hrefLang-${index}`}
                            rel="alternative"
                            hrefLang={lang?.hreflang.substring(0, 2)}
                            href={`${siteDomain}${lang?.href}`}
                        />
                    ))}
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content={siteName || 'BUSH’S® Beans'}/>
                {metadataUrl && <meta property="og:url" content={`${siteDomain}${metadataUrl}`}/>}
                {metadataTitle && <meta property="og:title" content={metadataTitle}/>}
                {metadataDescription && !hideBranding &&
                    <meta property="og:description" content={metadataDescription}/>}
                {hideBranding && <meta name="robots" content="noindex, nofollow"/>}
                {metadataImage && <meta property="og:image" content={`${siteDomain}${metadataImage}`}/>}
                {/* Twitter */}
                <meta property="twitter:card" content={metadataImage ? 'summary_large_image' : 'summary'}/>
                {metadataUrl && <meta property="twitter:url" content={`${siteDomain}${metadataUrl}`}/>}
                {metadataTitle && <meta property="twitter:title" content={metadataTitle}/>}
                {metadataDescription && <meta property="twitter:description" content={metadataDescription}/>}
                {metadataImage && <meta property="twitter:image" content={`${siteDomain}${metadataImage}`}/>}
                <meta name="google-site-verification" content="R5SVmDMYdTSwBSEA4ANKPsUH5BHdqVQeSB7zWcYLYZo"/>
                <link rel="preconnect" href="https://www.googletagmanager.com" />
                {/* recipe schema */}
                {recipeInfo?.recipeSchemaJson && (
                    <script type="application/ld+json">{sitecoreContext.recipeInfo.recipeSchemaJson}</script>
                )}
                {/* basketful */}
                {site?.name !== "bushs-beans-foodservices" && (
                    <script src="https://bonsai.basketful.co/basketful.js" async></script>
                )}
                {/* PIXEL FOR VISITORS CENTER PAGE */}
                { (pageTitle === "Visitor Center" || route?.fields?.pageTitle?.value === "Visitor Center") && (
                    <script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript"></script>
                )}
                { (pageTitle === "Visitor Center" || route?.fields?.pageTitle?.value === "Visitor Center") && (
                    <script type="text/javascript">{`
                        if (typeof window !== 'undefined') {
                            ttd_dom_ready(function() {
                                if (typeof TTDUniversalPixelApi === 'function') {
                                    var universalPixelApi = new TTDUniversalPixelApi();
                                    universalPixelApi.init("ku9eeav", ["nalx8is"], "https://insight.adsrvr.org/track/up");
                                }
                            });
                        }
                    `}
                    </script>
                )}
                {/* Adobe Analytics */}
                {/* <script src={process.env.REACT_APP_ADOBE_ANALYTICS} async/> */}
                
                {/* Microsoft/Bing */}
                {/*
        <script>{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"4075953"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</script>
        */}
                {site?.name === "bushs-beans-foodservices" && (
                    <script type="text/javascript">{`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-M7X7N2');
                    `}</script>

                )}
                {site?.name !== "bushs-beans-foodservices" && (
                    <script type="text/javascript">{`
                if(typeof window !=='undefined'){
                    const gid = (new URL(window.location.href)).hostname.split('bushbeans').pop() === '.ca' ? 'GTM-PNZXTQLJ' : 'GTM-5NSMC9T';
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({
                            'gtm.start': new Date().getTime(),
                            event: 'gtm.js'
                        });
                        var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s), 
                            dl = l != 'dataLayer' ? '&l=' + l : ''; 
                        j.async = true; 
                        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', gid);
                }
            `}</script>
                )}
            </Helmet>
            <GlobalStyle/>
            {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
            <VisitorIdentification/>
            <AlertRibbon/>
            {site?.name === "bushs-beans-foodservices" && <UtilityNav search={search} setSearch={setSearch}/>}
            {!hideBranding && <MainNav search={search} setSearch={setSearch}/>}
            {!hideBranding &&
                <section aria-label="Search Container">
                    <SearchBoxContainer search={search} ref={searchRef} className="hide-on-print">
                        <SearchBoxInner search={search}>
                            <SearchBox
                                searchValue={searchTerm}
                                showError={noSearchValue}
                                onChange={(event) => {
                                    setSearchTerm(event);
                                    if (noSearchValue) setNoSearchValue(false);
                                }}
                                onSubmit={(event, inputValue) => {
                                    if (searchTerm.length > 0) {
                                        window.location.href = `${globalContent.homepageLink}search/?type=all&searchTerm=${searchTerm}`;
                                        setSearch(false);
                                    } else {
                                        setNoSearchValue(true);
                                    }
                                }}
                                className="search-box"
                            />
                        </SearchBoxInner>
                    </SearchBoxContainer>
                    <Overlay search={search}/>
                </section>}
            {/* root placeholder for the app, which we add components to using route data */}
            <main className="container">
                <Placeholder name="jss-main" rendering={route}/>
                <div id="modal-container"/>
            </main>
            {!hideBranding && <Footer/>}
        </React.Fragment>
    );
};

export default withSitecoreContext()(Layout);