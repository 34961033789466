import React from 'react';

const CirclePlayBtnSvg = (props) => (
    <svg width={128} height={128} {...props}>
        <defs>
            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                <feDropShadow dx="0" dy="4" stdDeviation="4"/>
            </filter>
        </defs>
        <g filter="url(#shadow)">
            <path
                d="M111.75 63.532c0 26.348-21.338 47.5-47.5 47.5a47.354 47.354 0 0 1-47.5-47.5c0-26.162 21.152-47.5 47.5-47.5 26.162 0 47.5 21.338 47.5 47.5ZM51.633 43.493c-1.485.742-2.227 2.227-2.227 3.711V79.86c0 1.67.742 3.155 2.227 3.897 1.299.928 3.154.742 4.453 0l26.719-16.328c1.298-.928 2.226-2.227 2.226-3.897 0-1.484-.928-2.968-2.226-3.71l-26.72-16.329c-1.298-.928-3.153-.928-4.452-.185v.185Z"
            />
        </g>
    </svg>
);

export default CirclePlayBtnSvg;